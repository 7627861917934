import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import product1 from '../../assets/images/Todzee/classroom/IMG_1.jpg';
import product2 from '../../assets/images/Todzee/classroom/IMG_2.jpg';
import product3 from '../../assets/images/Todzee/classroom/IMG_3.jpg';
import product4 from '../../assets/images/Todzee/classroom/IMG_4.jpg';
import product5 from '../../assets/images/Todzee/classroom/IMG_5.jpg';

const FeaturedItems = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const [products] = useState([
    {
      id: 1,
      image: product1,
      class: 'Daycare',
      age: '0.6yr+',
      classSize: '15 - 20',
      desc: 'Where Little Ones Play, Learn, and Stay Safe All Day!',
    },
    {
      id: 2,
      image: product2,
      class: 'PlayGroup',
      age: '2yr+',
      classSize: '15 - 20',
      desc: 'Where Every Giggle, Discovery, and Friendship Begins!',
    },
    {
      id: 3,
      image: product3,
      class: 'Nursery',
      age: '3yr+',
      classSize: '15 - 20',
      desc: 'Nurturing Minds, Planting Dreams, and Blooming Futures!',
    },
    {
      id: 4,
      image: product4,
      class: 'LKG',
      age: '4yr+',
      classSize: '15 - 20',
      desc: 'Laying the Foundation for Bright Beginnings!',
    },
    {
      id: 5,
      image: product5,
      class: 'UKG',
      age: '5yr+',
      classSize: '15 - 20',
      desc: 'Growing Minds, Preparing for Great Adventures Ahead!',
    },
    {
      id: 6,
      image: product1,
      class: 'Daycare',
      age: '0.6yr+',
      classSize: '15 - 20',
      desc: 'Where Little Ones Play, Learn, and Stay Safe All Day!',
    },
    {
      id: 7,
      image: product2,
      class: 'PlayGroup',
      age: '2yr+',
      classSize: '15 - 20',
      desc: 'Where Every Giggle, Discovery, and Friendship Begins!',
    },
    {
      id: 8,
      image: product3,
      class: 'Nursery',
      age: '3yr+',
      classSize: '15 - 20',
      desc: 'Nurturing Minds, Planting Dreams, and Blooming Futures!',
    },
    {
      id: 9,
      image: product4,
      class: 'LKG',
      age: '4yr+',
      classSize: '15 - 20',
      desc: 'Laying the Foundation for Bright Beginnings!',
    },
    {
      id: 10,
      image: product5,
      class: 'UKG',
      age: '5yr+',
      classSize: '15 - 20',
      desc: 'Growing Minds, Preparing for Great Adventures Ahead!',
    },
  ]);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [items, setItems] = useState(1);

  const settings2 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings3 = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
    window.addEventListener('scroll', scrollNavigation, true);
    return () => {
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollNavigation = () => {
    const doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  const addItem = () => {
    setItems(items + 1);
  };

  const removeItem = () => {
    if (items > 1) {
      setItems(items - 1);
    }
  };

  return (
    <React.Fragment>
      <Container className="mt-80 mt-40">
        <Row className="align-items-center">
          <div className="text-center mb-2">
            <h2>Classroom</h2>
            <p>
              Children at every stage of their growth require different
              developmental needs and it is every parents and teachers
              responsibility to cater to the right developmental need and
              approach at the right time.
            </p>
          </div>
          <Col lg={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div id="two-testi" className="owl-carousel owl-theme">
              {isMobileView ? (
                <Slider
                  ref={slider2}
                  {...settings2}
                  className="owl-carousel owl-theme"
                >
                  {products.map((product, key) => (
                    <div key={key} style={{ marginLeft: 5, marginRight: 5 }}>
                      <Card className="shop-list border-0 shadow position-relative overflow-hidden m-3">
                        <div className="shop-image position-relative overflow-hidden shadow">
                          <Link to="#">
                            <img
                              src={product.image}
                              className="img-fluid"
                              alt="Landrick"
                            />
                          </Link>
                        </div>
                        <CardBody className="content p-4">
                          <h5>{product.class}</h5>
                          <ul className="list-unstyled text-muted mt-2 mb-0">
                            <li className="list-inline-item me-3">
                              <i className="uil uil-kid"></i>&nbsp;
                              {product.age}
                            </li>
                            <li className="list-inline-item">
                              <i className="uil uil-users-alt"></i>&nbsp;{' '}
                              {product.classSize}
                            </li>
                          </ul>
                          <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                            <li className="list-inline-item">
                              <b>{product.desc}</b>
                            </li>
                            {/* <li className="list-inline-item text-muted">
                            $ 850 / sqft
                          </li> */}
                          </ul>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Slider>
              ) : (
                <Slider
                  ref={slider2}
                  {...settings3}
                  className="owl-carousel owl-theme"
                >
                  {products.map((product, key) => (
                    <div key={key} style={{ marginLeft: 5, marginRight: 5 }}>
                      <Card className="shop-list border-0 shadow position-relative overflow-hidden m-3">
                        <div className="shop-image position-relative overflow-hidden shadow">
                          <Link to="#">
                            <img
                              src={product.image}
                              className="img-fluid"
                              alt="Landrick"
                            />
                          </Link>
                        </div>
                        <CardBody className="content p-4">
                          <h5>{product.class}</h5>
                          <ul className="list-unstyled text-muted mt-2 mb-0">
                            <li className="list-inline-item me-3">
                              <i className="uil uil-kid"></i>&nbsp;
                              {product.age}
                            </li>
                            <li className="list-inline-item">
                              <i className="uil uil-users-alt"></i>&nbsp;{' '}
                              {product.classSize}
                            </li>
                          </ul>
                          <ul className="list-unstyled d-flex justify-content-between mt-2 mb-0">
                            <li className="list-inline-item">
                              <b>{product.desc}</b>
                            </li>
                            {/* <li className="list-inline-item text-muted">
                            $ 850 / sqft
                          </li> */}
                          </ul>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default FeaturedItems;
