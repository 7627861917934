// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Popup from '../../components/Layout/popup';

// Feature Images
import saas2 from '../../assets/images/Todzee/FounderDirector.png';

import Section from './Home';
import { agencyFeatureArray } from '../../common/data';

const IndexAgency = () => {
  return (
    <React.Fragment>
      {/* Feature */}
      <section className="section">
        <Container className="mt-30">
          <Row className="align-items-center">
            <Col lg={6} md={6}>
              <img src={saas2} className="img-fluid" alt="" />
            </Col>

            <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-3">
                <h4 className="title mb-4">About Founder</h4>
                <p className="text-muted" style={{ textAlign: 'justify' }}>
                  Lilly Rebecca, the esteemed founder and director of Alphabet
                  International Play School, established this school in 2024
                  with a passion to nurture young minds. Her compassionate
                  leadership and commitment to educational excellence has been
                  her driving force to shape young lives and foster a community
                  where curiosity and creativity thrive. Her vision is to create
                  a nurturing environment where children could explore, discover
                  and learn in a holistic way. Lilly’s hands-on approach and
                  dedication to each child’s individual growth have set our
                  school apart, emphasizing not just academic milestones but
                  also social and emotional development which plays a vital role
                  in the child’s holistic development.
                </p>
                {/* <Link to="#" className="mt-3 h6 text-primary">
                  Find Out More{' '}
                  <i className="uil uil-angle-right-b align-middle"></i>
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default IndexAgency;
