import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import bg03 from '../../assets/images/Todzee/life Skills.jpg';
import bg04 from '../../assets/images/Todzee/profileImage.jpg';

const GreatFeatures = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <Container className="mt-100 mt-60 mb-4">
        <Row className="">
          <Col lg={{ size: 5, order: 1 }} className="mt-4 pt-2 mt-lg-0 pt-lg-0">
            <div className=" sticky-bar">
              <h1 className="mb-3">Alphabet Learning Pedagogy</h1>
              <h5 className="text-muted">
                Alphabet International school curriculum emphasizes play based
                learning, social interaction, creative activities, and cognitive
                skills to support young children’s holistic development. Our
                school is aligned to the Cambridge curriculum in Nursery
                emphasizing holistic development through play, literacy,
                numeracy, and critical thinking, preparing young children for
                lifelong learning.
              </h5>
              <div className="">
                <div
                  className="mt-4 title"
                  style={{
                    backgroundColor: 'rgba(47, 85, 212, 0.1)',
                    padding: '40px',
                    fontSize: '21px',
                  }}
                >
                  <strong>
                    Our teachers aim at nurturing students with utmost care and
                    ensure the following each and every step
                  </strong>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
            <div>
              {isMobileView ? (
                <img
                  src={bg03}
                  alt="image"
                  style={{ height: '380px', width: '380px' }}
                />
              ) : (
                <img src={bg03} className="" alt="image" />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default GreatFeatures;
