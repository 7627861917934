// React Basic and Bootstrap
import React, { Component } from 'react';

// import generic components
// import Popup from '../../components/Layout/popup';
// import SearchBar from './SearchBar';
// import News from './News';

import Home from './Home';
import Gallery from './Gallery';
import Cta from './Cta';
import Services from './Services';
import Testimonials from './Testimonials';
import Contact from './Contact';
import Curriculam from './Curriculam';
import AboutUs from './AboutUs';
import Classroom from './Classroom1';
import Navbar from './NavbarPage';
import VisionMission from './VisionMission';

class Index extends Component {
  componentDidMount() {
    document.body.classList = '';
    // document.getElementById('topnav').classList.add('bg-white');
    // document.querySelector('.shoppingbtn').classList.add('btn-primary');
    window.addEventListener('scroll', this.scrollNavigation, true);

    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  handleScroll() {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
        document.getElementById('topnav').classList.add('bg-white');
      } else {
        navBar.classList.remove('nav-sticky');
        document.getElementById('topnav').classList.remove('bg-white');
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />

        <div id="home">
          <Home />
        </div>

        <div>
          <VisionMission />
        </div>

        <div id="aboutus">
          <AboutUs />
        </div>

        <div id="classroom">
          <Classroom />
        </div>

        <div id="gallery">
          <Gallery />
        </div>

        {/* Video */}
        <Cta />

        <div id="curriculam">
          <Curriculam />
        </div>

        {/* Services */}
        <Services />

        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M720 125L2160 0H2880V250H0V125H720Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        {/* <Testimonials /> */}

        <div id="contact">
          <Contact />
        </div>

        {/* <Popup /> */}
      </React.Fragment>
    );
  }
}

export default Index;
