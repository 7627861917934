// React Basic and Bootstrap
import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Button,
  Label,
  Card,
  CardBody,
} from 'reactstrap';

// import seoSVG from '../../assets/images/illustrator/SEO_SVG.svg';
import VisionImg from '../../assets/images/Todzee/vision.jpeg';
// import MissionImg from '../../assets/images/SISeshanCharitableTrust/kid3-transformed.jpeg';

class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [
        { title: 'Digital Marketing Solutions for Tomorrow1' },
        { title: 'Our Talented & Experienced Marketing Agency' },
        { title: 'Create your own skin to match your brand' },
      ],
      features1: [
        { title: 'Digital Marketing Solutions for Tomorrow11' },
        { title: 'Our Talented & Experienced Marketing Agency' },
        { title: 'Create your own skin to match your brand' },
      ],
      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section ">
          <Container>
            <div className="text-center">
              <h2> Welcome to Alphabet Play School and Daycare</h2>
              <p>
                At Alphabet Play School and Daycare, we believe in nurturing
                young minds with love, care, and the joy of learning. Our
                state-of-the-art facilities, experienced educators, and engaging
                curriculum create a vibrant environment where children can
                thrive and grow.
              </p>
            </div>

            <Row
              className="align-items-center"
              // data-aos="fade-up"
              // data-aos-duration="1800"
            >
              <Col lg="5" md="6" className="mt-4 pt-2">
                <img
                  src={VisionImg}
                  alt=""
                  style={{ height: '500px', width: '400px' }}
                />
              </Col>

              <Col lg="7" md="6" className="mt-4 pt-2">
                <div className="section-title ms-lg-5">
                  <h4 className="title mb-4">Our Vision</h4>
                  <p className="text text-align-justify">
                    At Alphabet Play School and Day Care, we are committed to
                    providing world-class education and care to young minds.
                  </p>
                  <ul className="list-unstyled text">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Commitment to Excellence: At Alphabet Play School and Day
                      Care, we provide world-class education and care to young
                      minds.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Advanced Curriculum: Our state-of-the-art facilities and
                      Cambridge-aligned curriculum nurture a love for learning,
                      creativity, and character development.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Holistic Development: We ensure every child reaches their
                      full potential academically while developing essential
                      social and emotional skills.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      Lifelong Success: Our nurturing environment prepares
                      children for lifelong success and happiness.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      To provide professional support for child development.
                    </li>
                  </ul>
                  {/* <Link to="#" className="mt-3 h6 text-primary">
                    Find Out More{' '}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link> */}
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col
                lg="12"
                md={{ size: 12, order: 1 }}
                xs={{ order: 2 }}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <div className="section-title">
                  <h4 className="title mb-2 text-center">
                    Why Choose Alphabet Play School and Daycare?
                  </h4>
                  <p className="text text-align-justify">
                    Our mission is to provide a comprehensive preschool
                    education that focuses on the holistic development of
                    children, including cognitive, social, emotional, and
                    physical growth.
                  </p>
                  <ul className="list-unstyled text">
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>{' '}
                      <strong>Safe and Nurturing Environment:</strong> Your
                      child’s safety and well-being are our top priorities. Our
                      secure facility and caring staff ensure that your child
                      feels safe, happy, and loved.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      <strong>Experienced and Qualified Educators:</strong> Our
                      dedicated team of educators are passionate about early
                      childhood education. With years of experience and
                      training, they provide the highest quality care and
                      education for your child.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      <strong>Engaging Curriculum:</strong> Our play-based
                      curriculum is designed to foster creativity, curiosity,
                      and a love for learning. Through hands-on activities,
                      games, and exploration, children develop important skills
                      while having fun.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      <strong>Holistic Development:</strong> We focus on the
                      holistic development of every child, nurturing their
                      social, emotional, physical, and cognitive development.
                    </li>
                    <li className="mb-1">
                      <span className="text-primary h5 me-2 text-align-justify">
                        <i className="uil uil-check-circle align-middle"></i>
                      </span>
                      <strong>Convenient Daycare Services:</strong> We offer
                      flexible daycare options to accommodate your busy
                      schedule. Whether you need full-time or part-time care,
                      we’re here to support your family’s needs.
                    </li>
                  </ul>
                </div>
              </Col>

              {/* <Col lg={5} md={6} className="order-1 order-md-2">
                <img
                  src={MissionImg}
                  alt=""
                  style={{ height: '500px', width: '485px' }}
                />
              </Col> */}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default HowItWorks;
